<template>
	<div class="modal" style="display:block">
		<!-- <div class="modal-content" v-bind:class="{ 'bravo' : hasError === false}"> -->
        <div class="modal-content">

            <template v-if="step == 1">
                <div class="ordinateur">
                    <p>Entrez votre mot de passe</p>
                    <input ref="password" v-model="form.password" type="text" maxlength="10">

                    <p v-if="hasError == true">Mot de passe erroné, veuillez réessayer</p>

                    <button v-if="hasError !== false" type="button" class="btn btn-blue align-center" @click="checkPassword()"><span>Valider</span></button>
                </div>
            </template>

            <template v-if="step == 2">
                <div class="ordinateur-applis">
                    <span class="number">1</span>
                    <span class="link" @click="goNext()"></span>
                </div>
            </template>

            <template v-if="step == 3">
                <div class="chat">
                    <div class="bulle bulle1">Bonjour Docteur, <br>J’ai fait mes propres recherches et des gens de ma famille m’ont conseillé des traitements. Je suis un peu perdu…</div>
                    <div class="bulle bulle2 bulle-right">Voyons cela ensemble</div>
                </div>
            </template>

        </div>

        <div class="modal-footer">

            <!-- ! plus de retour possible on est dans le dernier tunnel du jeu -->
            <!-- <button v-if="hasError !== false" type="button" class="btn btn-blue align-left" @click="close()"><span>Retour</span></button> -->

            <button v-if="hasError == false && step == 3" @click="lanceChat()" type="button" class="btn btn-blue align-right"><span>Suivant</span></button>
        </div>
    </div>
</template>


<script>
import { EventBus } from '@/core/event-bus.js';

export default {
	name: 'OrdiMedecinFin',
    data() {
        return {
            step : 1,
            form : {
                password: null,
            },
            hasError: null
        }
    },
    computed : {
    },
	methods : {
		close() {
			this.$emit('close')
		},
        goNext(){
            this.step++;
        },
        checkPassword() {
            if ( this.form.password == "1515-toto" ) {
                this.hasError = false
                this.goNext()
            } else {
                this.hasError = true
            }
        },
        lanceChat() {
            this.goNext()

            // QuizChat
            if (this.hasError == false && this.step == 4) {
                EventBus.$emit('changeQuiz', 'radio-button-chat')
            }
        }
    }
}
</script>


<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";

    .modal-content {
        padding: 0;
    }

    .ordinateur {
        background: url('/img/ordinateur.jpg') no-repeat 0 0;
        background-size: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            color: white;
            font-weight: 500;
        }

        input {
            border: none;
            font-size: 4.2rem;
            height: 5.5rem;
            margin-bottom: 3rem;
            text-align: center;
            width: 25rem;
        }

        .btn {
            position: relative;
            left: auto;
            transform: none;
        }
    }

    .ordinateur-applis {
        background: url('/img/ordi-open-appli.jpg') no-repeat 0 0;
        background-size: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .link {
            cursor: pointer;
            position: absolute;
            height: 17rem;
            width: 20rem;
            top: 35.5rem;
            left: 55rem;
        }

        .number {
            background: red;
            color: white;
            border-radius: 50%;
            line-height: 3rem;
            height: 3rem;
            width: 3rem;
            position: absolute;
            top: 48rem;
            left: 73rem;
        }
    }

    .chat {
        background: url('/img/bg-chat.png') no-repeat 0 0;
        background-size: 100%;
        height: 100%;
        position: relative;

        .bulle {
            background: #DDDDDD;
            font-size: 1.7rem;
            font-weight: 500;
            padding: 1.5rem 2rem;
            position: absolute;
            text-align: left;

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-top: 2rem solid #DDDDDD;
                border-left: 2rem solid transparent;
                position: absolute;
                top: 0;
                left: -2rem;
            }

            &-right {
                &:before {
                    border-left: none;
                    border-right: 2rem solid transparent;
                    right: -2rem;
                    left: auto;
                }
            }

            &1 {
                top: 15rem;
                left: 20rem;
                width: 50%;
            }

            &2 {
                top: 35rem;
                right: 6.5rem;
                width: auto;
            }
        }
    }
</style>